import {Injectable} from '@angular/core';
import {IdentityVaultConfig} from '@ionic-enterprise/identity-vault';
import {StorageService} from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class NisBrowserVault {

    private key: string;

    constructor(private storage: StorageService) {
    }

    public async initialize(config: IdentityVaultConfig): Promise<void> {
        this.key = config.key;
    }

    public async getValue<T = any>(key: string): Promise<T | null> {
        return this.storage.get(this.getStorageKey(key));
    }

    public async setValue<T = any>(key: string, value: T): Promise<void> {
        this.storage.set(this.getStorageKey(key), value);
    }

    public async clear(): Promise<void> {
        const allItems = await this.storage.getKeys();
        if (allItems) {
            allItems.forEach((key: string) => {
                if (key.startsWith(this.key)) {
                    this.storage.remove(key);
                }
            });
        }
    }

    private getStorageKey(key: string): string {
        return `${this.key}.${key}`;
    }
}
