import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';
import {ToolConfigInterface} from './nis-lib/types/tool/tool.types';
import {Utils} from './nis-lib/helpers/utils';

export class NisRouteReuseStrategy extends RouteReuseStrategy {
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return null;
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
        return;
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        const result: boolean = future.routeConfig === curr.routeConfig;

        if (this.shouldReuseForArt(future, curr)) {
            return true;
        }
        if (this.shouldReuseForWorkflow(future, curr)) {
            return true;
        }

        if (result) {
            for (const key of future.paramMap.keys) {
                if (future.paramMap.get(key) !== curr.paramMap.get(key)) {
                    return false;
                }
            }

            for (let i = 0; i < future.url.length; i++) {
                const futureUrlSegment = future.url[i];
                const currentUrlSegment = curr.url[i];
                if (Utils.isNullOrUndefined(futureUrlSegment) !== Utils.isNullOrUndefined(currentUrlSegment)) {
                    return false;
                }
                for (const key of futureUrlSegment.parameterMap.keys) {
                    if (futureUrlSegment.parameterMap.get(key) !== currentUrlSegment.parameterMap.get(key)) {
                        return false;
                    }
                }
                for (const key of currentUrlSegment.parameterMap.keys) {
                    if (currentUrlSegment.parameterMap.get(key) !== futureUrlSegment.parameterMap.get(key)) {
                        return false;
                    }
                }
            }
            if (!this.shouldReuseForWorld(future, curr)) {
                return false;
            }
        }

        return result;
    }

    private shouldReuseForWorld(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        if (!future.firstChild && !curr.firstChild && future.queryParamMap.get('world') && curr.queryParamMap.get(
            'world')) {
            if (future.queryParamMap.get('world') !== curr.queryParamMap.get('world')) {
                let route: ActivatedRouteSnapshot = future.parent;
                while (route) {
                    if (route.data && (route.data as ToolConfigInterface).userPermission) {
                        return !(route.data as ToolConfigInterface).availableInWorlds;
                    }
                    route = route.parent;
                }
            }
        }
        return true;
    }

    private shouldReuseForArt(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        // Prevent art tab from being reloaded when changing active art case in detail panel. Else the detail panel is
        // being closed and opened again every time the art case changes
        if (future.url && future.url.length > 0 && future.url[0]) {
            if (curr.url && curr.url.length > 0 && curr.url[0]) {
                if (future.url[0].path === 'artCase' && curr.url[0].path === 'artCase') {
                    return future.params.id !== curr.params.id;
                }
            }
        }
        return false;
    }

    private shouldReuseForWorkflow(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        // Prevent workflow tab from being reloaded when changing workflow.
        if (future.url && future.url.length > 0 && future.url[0] && (future.outlet !== 'south' || curr.outlet !== 'south')) {
            if (curr.url && curr.url.length > 0 && curr.url[0]) {
                if (future.url[0].path === 'workflow' && curr.url[0].path === 'workflow') {
                    return future.queryParams.projectUrn === curr.queryParams.projectUrn;
                }
            }
        }
        return false;
    }
}
