import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {ProjectContainerService} from './project-container.service';

@Injectable()
export class NisDesignHttpInterceptorService implements HttpInterceptor {

    constructor(private router: Router,
                private projectContainerService: ProjectContainerService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (this.projectContainerService.isProjectContainerOpen()
            && !this.projectContainerService.includesProjectContainerParams(req.params)) {
            const containerParams: HttpParams = this.projectContainerService.appendProjectContainerParams(req.params);
            req = req.clone({
                params: containerParams
            });
        }
        return next.handle(req);
    }
}
