import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Subscriber} from 'rxjs';
import {HTTP, HTTPResponse} from '@awesome-cordova-plugins/http/ngx';
import {NisHttpRequest, NisHttpRequestType} from '../../types/http/http-request.types';
import {PlatformService} from '../platform/platform.service';
import {MessageService} from '../message/message.service';
import {ProjectContainerService} from '../design/project-container.service';
import {Directory, Filesystem} from '@capacitor/filesystem';
import {AuthInterceptService} from '../auth/auth-intercept.service';

@Injectable({
    providedIn: 'root'
})
export class NisHttpAppService {
    constructor(private authIntercept: AuthInterceptService,
                private httpClient: HttpClient,
                private httpCordova: HTTP,
                private platformService: PlatformService,
                private messageService: MessageService,
                private projectContainerService: ProjectContainerService) {
    }

    get<T>(request: NisHttpRequest, doHandleResponse: boolean = true, doHandleError: boolean = true) {
        this.addBearerToken(request).then(() => {
            if (this.platformService.isElectron) {
                this.httpClient.get<T>(request.requestData.url, request.requestData.options).toPromise().then(
                    (response) => {
                        if (doHandleResponse) {
                            this.handleElectronResponse<T>(response, request.subscriber, request);
                        } else {
                            request.subscriber.next(response);
                            request.subscriber.complete();
                        }
                    }, (response: HttpErrorResponse) => {
                        if (doHandleError) {
                            this.handleElectronErrorResponse<T>(response, request.subscriber, request);
                        } else {
                            request.subscriber.next();
                            request.subscriber.complete();
                        }
                    });
            } else {
                const cordovaOptions = this.getCordovaParameters(request.requestData.options);
                this.addProjectContainerParams(request, cordovaOptions.params);
                this.httpCordova.get(request.requestData.url, cordovaOptions.params, cordovaOptions.headers).then(
                    (response: HTTPResponse) => {
                        if (doHandleResponse) {
                            this.handleCordovaResponse<T>(response, request.subscriber, request);
                        } else {
                            request.subscriber.next(response.data);
                            request.subscriber.complete();
                        }
                    },
                    async (response: HTTPResponse) => {
                        if (doHandleError) {
                            await this.handleCordovaErrorResponse<T>(response, request.subscriber);
                        } else {
                            request.subscriber.next();
                            request.subscriber.complete();
                        }
                    });
            }
        });
    }

    getBlob<T>(request: NisHttpRequest, doHandleResponse: boolean = true) {
        this.addBearerToken(request).then(() => {
            if (this.platformService.isElectron) {
                this.get(request, doHandleResponse);
            } else {
                const cordovaOptions = this.getCordovaParameters(request.requestData.options);
                this.addProjectContainerParams(request, cordovaOptions.params);
                this.httpCordova.sendRequest(request.requestData.url,
                    { method: 'get', responseType: 'blob', headers: cordovaOptions.headers, params: cordovaOptions.params }).then(
                    (response: HTTPResponse) => {
                        if (doHandleResponse) {
                            this.handleCordovaResponse<T>(response, request.subscriber, request);
                        } else {
                            request.subscriber.next(response.data);
                            request.subscriber.complete();
                        }
                    },
                    async (response: HTTPResponse) => {
                        await this.handleCordovaErrorResponse<T>(response, request.subscriber);
                    });
            }
        });
    }

    getText<T>(request: NisHttpRequest) {
        this.addBearerToken(request).then(() => {
            if (this.platformService.isElectron) {
                this.httpClient.get<T>(request.requestData.url, request.requestData.options).toPromise().then(
                    (response) => {
                        this.handleElectronResponse<T>(response, request.subscriber, request);
                    }, (response: HttpErrorResponse) => {
                        this.handleElectronErrorResponse<T>(response, request.subscriber, request);
                    });
            } else {
                const cordovaOptions = this.getCordovaParameters(request.requestData.options);
                this.addProjectContainerParams(request, cordovaOptions.params);
                this.httpCordova.get(request.requestData.url, cordovaOptions.params, cordovaOptions.headers).then(
                    (response: HTTPResponse) => {
                        request.subscriber.next(response.data);
                        request.subscriber.complete();
                    },
                    async (response: HTTPResponse) => {
                        await this.handleCordovaErrorResponse<T>(response, request.subscriber);
                    });
            }
        });
    }

    post<T>(request: NisHttpRequest, doHandleError: boolean = true) {
        this.addBearerToken(request).then(() => {
            if (this.platformService.isElectron) {
                this.httpClient.post<T>(request.requestData.url, request.requestData.body,
                    request.requestData.options).toPromise().then(
                    (response) => {
                        this.handleElectronResponse<T>(response, request.subscriber, request);
                    }, (response: HttpErrorResponse) => {
                        if (doHandleError) {
                            this.handleElectronErrorResponse<T>(response, request.subscriber, request);
                        } else {
                            request.subscriber.next();
                            request.subscriber.complete();
                        }
                    });
            } else {
                const cordovaOptions = this.getCordovaParameters(request.requestData.options);
                const body = this.getBody(request);
                this.httpCordova.setDataSerializer(request.requestData.serializer || 'json');
                this.addParamsToUrl(request, cordovaOptions.params);
                this.addProjectContainerParams(request, body);
                this.httpCordova.post(request.requestData.url, body, this.getAdditionalHeaders(request)).then(
                    (response: HTTPResponse) => {
                        this.handleCordovaResponse<T>(response, request.subscriber, request);
                    }, async (response: HTTPResponse) => {
                        if (doHandleError) {
                            await this.handleCordovaErrorResponse<T>(response, request.subscriber);
                        } else {
                            request.subscriber.next();
                            request.subscriber.complete();
                        }
                    });
            }
        });
    }

    patch<T>(request: NisHttpRequest) {
        this.addBearerToken(request).then(() => {
            if (this.platformService.isElectron) {
                this.httpClient.patch<T>(request.requestData.url, request.requestData.body,
                    request.requestData.options).toPromise().then(
                    (response) => {
                        this.handleElectronResponse<T>(response, request.subscriber, request);
                    });
            } else {
                const body: any = this.getBody(request);
                this.httpCordova.setDataSerializer(request.requestData.serializer || 'json');
                this.addProjectContainerParams(request, body);
                this.httpCordova.patch(request.requestData.url, body, this.getAdditionalHeaders(request)).then(
                    (response: HTTPResponse) => {
                        this.handleCordovaResponse<T>(response, request.subscriber, request);
                    });
            }
        });
    }

    /**
     * Construct a PUT request which interprets the body as text and returns it.
     *
     * @return an `Observable` of the body as a `string`.
     */
    put<T>(request: NisHttpRequest) {
        this.addBearerToken(request).then(() => {
            if (this.platformService.isElectron) {
                this.httpClient.put<T>(request.requestData.url, request.requestData.body,
                    request.requestData.options).toPromise().then(
                    (response) => {
                        this.handleElectronResponse<T>(response, request.subscriber, request);
                    }, (response: HttpErrorResponse) => {
                        this.handleElectronErrorResponse<T>(response, request.subscriber, request);
                    });
            } else {
                this.httpCordova.setDataSerializer('json');
                this.addProjectContainerParams(request, request.requestData.body);
                this.httpCordova.put(request.requestData.url, request.requestData.body, this.getAdditionalHeaders(request)).then(
                    (response: HTTPResponse) => {
                        this.handleCordovaResponse<T>(response, request.subscriber, request);
                    }, async (response: HTTPResponse) => {
                        await this.handleCordovaErrorResponse<T>(response, request.subscriber);
                    });
            }
        });
    }

    delete<T>(request: NisHttpRequest) {
        this.addBearerToken(request).then(() => {
            if (this.platformService.isElectron) {
                this.httpClient.delete(request.requestData.url, request.requestData.options).toPromise().then(
                    (response) => {
                        this.handleElectronResponse<T>(response, request.subscriber, request);
                    }, (response: HttpErrorResponse) => {
                        this.handleElectronErrorResponse<T>(response, request.subscriber, request);
                    });
            } else {
                const cordovaOptions = this.getCordovaParameters(request.requestData.options);
                this.httpCordova.setDataSerializer('json');
                this.addParamsToUrl(request, cordovaOptions.params);
                this.addProjectContainerParams(request, request.requestData.body);
                this.httpCordova.delete(request.requestData.url, request.requestData.body, this.getAdditionalHeaders(request)).then(
                    (response: HTTPResponse) => {
                        request.subscriber.next(response.data);
                        request.subscriber.complete();
                    }, async (response: HTTPResponse) => {
                        await this.handleCordovaErrorResponse<T>(response, request.subscriber);
                    });
            }
        });
    }

    // TODO: Quick fix for support 2.7 & develop was implemented, has to change for develop-change (fileservice)
    public async download(url: string, fileName: string) {
        if (this.platformService.isAndroid) {
            fileName = encodeURI(fileName);
        }
        url = url.replace(/:\d{1,5}\//g, '/');
        try {
            return Filesystem.downloadFile({
                url: this.addProjectContainerParamsToUrl(url),
                path: fileName,
                directory: Directory.Data,
                headers: this.authIntercept.shouldAddBearerToken(url) ? await this.getHeadersWithAuth() : null
            });
        } catch (reason) {
            console.log(reason);
        }
    }

    private getAdditionalHeaders(request: NisHttpRequest) {
        return request.requestData.options.headers ? this.getCordovaParameters(
            request.requestData.options).headers : {};
    }

    private getBody(request: NisHttpRequest) {
        return request.requestData.body instanceof HttpParams ? this.mapToObject(
            request.requestData.body) : request.requestData.body || {};
    }

    private handleCordovaResponse<T>(response: HTTPResponse, subscriber: Subscriber<T>,
                                     request: NisHttpRequest) {
        if (response.headers['content-type'].includes('json')) {
            subscriber.next(JSON.parse(response.data) as T);
        } else if (response.headers['content-type'].indexOf('text/html') > -1) {
            subscriber.error('nis.login_page.server_not_reachable');
        } else {
            subscriber.next(response.data);
        }
        subscriber.complete();
    }

    private async handleCordovaErrorResponse<T>(response: any, subscriber: Subscriber<T>) {
        const httpErrorResponse: HttpErrorResponse = this.messageService.throwErrorMessage(response);
        await this.authIntercept.interceptError(httpErrorResponse);
        if (httpErrorResponse) {
            subscriber.error(httpErrorResponse.error);
        }
    }

    private handleElectronResponse<T>(response: any, subscriber: Subscriber<T>, request: NisHttpRequest) {
        subscriber.next(response);
        subscriber.complete();
    }

    private handleElectronErrorResponse<T>(response: HttpErrorResponse, subscriber: Subscriber<T>,
                                           request: NisHttpRequest) {
        let errorMessage: string;
        if (response.status <= 0 || response.status === 503) {
            errorMessage = 'nis.login_page.server_not_reachable';
        } else if (response.status === 200) {
            if (response.error && response.error.text) {
                errorMessage = 'nis.login_page.server_not_reachable';
            }
        } else if (response.status === 403) {
            errorMessage = 'nis.no_permission_general';
        } else {
            errorMessage = 'nis.error_message';
        }

        if (!MessageService.suppressError && errorMessage) {
            const error = new HttpErrorResponse(
                {
                    status: response.status,
                    error: errorMessage,
                    url: response.url,
                    headers: response.headers,
                    statusText: response.statusText
                }
            );
            MessageService.onError.next(error);
        }
        if (errorMessage) {
            subscriber.error(errorMessage);
        }
    }

    private getCordovaParameters(options: {
        headers?: HttpHeaders | {
            [header: string]: string;
        };
        params?: HttpParams | {
            [param: string]: string;
        };
    }): {
        params: {
            [param: string]: string;
        };
        headers: {
            [header: string]: string;
        };
    } {
        let params: {
            [param: string]: string;
        } = {};
        let headers: {
            [header: string]: string;
        } = {};

        if (options) {
            const optionsParams: HttpParams | {
                [param: string]: string;
            } = options.params;
            const optionsHeaders: HttpHeaders | {
                [header: string]: string;
            } = options.headers;

            params = optionsParams instanceof HttpParams ? this.mapToObject(optionsParams) : optionsParams || {};
            headers = optionsHeaders instanceof HttpHeaders ? this.mapToObject(optionsHeaders) : optionsHeaders;
        }
        return {
            params: params,
            headers: headers
        };
    }

    private mapToObject(input: { keys(): string[], get(name: string): string }): { [header: string]: string } {
        const result: { [header: string]: string } = {};
        input.keys().forEach((key: string) => {
            result[key] = input.get(key);
        });
        return result;
    }

    private addProjectContainerParams(request: NisHttpRequest, params: any) {
        if (request.requestData.type === NisHttpRequestType.get || request.requestData.type === NisHttpRequestType.delete) {
            const projectContainerInfo = this.projectContainerService.getAdditionalParamsArray();
            if (projectContainerInfo) {
                for (const key of Object.getOwnPropertyNames(projectContainerInfo)) {
                    params[key] = projectContainerInfo[key];
                }
            }
        } else {
            request.requestData.url = this.addProjectContainerParamsToUrl(request.requestData.url);
        }
    }

    private addProjectContainerParamsToUrl(url: string) {
        const projectContainerInfo = this.projectContainerService.getAdditionalParamsString(url);
        if (projectContainerInfo) {
            return url + projectContainerInfo;
        }
        return url;
    }

    private async addBearerToken(request: NisHttpRequest) {
        request.requestData.options = request.requestData.options || {};
        if (this.authIntercept.shouldAddBearerToken(request.requestData.url)) {
            request.requestData.options.headers = request.requestData.options.headers || new HttpHeaders();
            request.requestData.options.headers = await this.authIntercept.addBearerToken(request.requestData.options.headers);
        }
    }

    private async getHeadersWithAuth(httpHeaders: HttpHeaders = new HttpHeaders()) {
        const headersWithAuth = await this.authIntercept.addBearerToken(httpHeaders);
        const bearerToken = headersWithAuth.get('Authentication');
        return { Authentication: 'Bearer ' + bearerToken };
    }

    private addParamsToUrl(request: NisHttpRequest, params: {
        [param: string]: string;
    }) {
        let useAmpersand = request.requestData.url.indexOf('?') > -1;
        for (const key of Object.getOwnPropertyNames(params)) {
            request.requestData.url += `${useAmpersand ? '&' : '?'}${key}=${params[key]}`;
            useAmpersand = true;
        }
    }
}
