import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingComponent} from './loading.component';
import {LoadingSpinnerComponent} from './loading-spinner/loading-spinner.component';
import {LoadingScreenComponent} from './loading-screen/loading-screen.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
        LoadingComponent,
        LoadingScreenComponent,
        LoadingSpinnerComponent
    ],
    exports: [
        LoadingComponent,
        LoadingScreenComponent,
        LoadingSpinnerComponent
    ]

})
export class LoadingModule {
}
