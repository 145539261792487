export enum NisModule {
    Base = 'BASE',
    Art = 'ART',
    Avis = 'AVIS',
    Connect = 'CONNECT',
    Design = 'DESIGN',
    Dxf = 'DXF',
    Mandant = 'MANDANT',
    MeterData = 'METER_DATA',
    Mobile = 'MOB',
    NetMonitoring = 'NET_MONITORING',
    Planauskunft = 'PA',
    SapMonitoring = 'SAP_MONITORING',
    Sketch = 'SKETCH',
    Survey = 'SURVEY',
    OwnerList = 'OWNER_LIST'
}

export enum NisUserPermission {
    None = 'None',
    isAdmin = 'is_admin',
    isNotified = 'is_notified',
    hasDxfExport = 'has_dxfexport',
    hasSketchView = 'has_sketchView',
    hasSketchEdit = 'has_sketchEdit',
    hasSapMonitoring = 'has_sapMonitoring',
    hasObjectBrowser = 'has_objectBrowser',
    hasArt = 'has_art',
    hasFileService = 'has_fileservice',
    hasTag = 'has_tag',
    hasAvis = 'has_avis',
    hasNetMonitoring = 'has_netMonitoring',
    hasSurvey = 'has_survey',
    hasDesign = 'has_design',
    hasMeterData = 'has_meterData',
    hasConnectView = 'has_connectView',
    hasConnectEdit = 'has_connectEdit',
    hasObjectInfo = 'has_objectInfo',
    hasInterna = 'has_interna',
    hasReportList = 'has_reportList',
    hasTrace = 'has_trace',
    hasOwnerList = 'has_ownerList'
}

export enum NisApp {
    nx = 'nisxplorer',
    pa = 'planauskunft',
    connect = 'connect',
    admin = 'control'
}

export enum ClientType {
    web,
    mobile
}

export enum WorldType {
    werkplan = 'Werkplan',
    schema = 'Schema',
    offline = 'Offline',
    vector = 'Vector'
}

export enum NisRole {
    admin = 'nis.admin',
    nx = 'nis.nx',
    pa = 'nis.pa',
    connect = 'nis.connect'
}

export interface NisAppAuthorization {
    app: NisApp;
    role: NisRole;
}
