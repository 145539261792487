import {Injectable} from '@angular/core';
import {HttpClient, HttpContext, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, Subscriber} from 'rxjs';
import {NisHttpRequestType} from '../../types/http/http-request.types';
import {NisHttpAppService} from './nis-http-app.service';
import {AppService} from '../app/app.service';
import {PlatformService} from '../platform/platform.service';
import {Utils} from '../../helpers/utils';
// eslint-disable-next-line boundaries/no-ignored
import {environment} from '../../../../environments/environment';
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NisHttpWrapperService {
    constructor(private platformService: PlatformService,
                private httpClient: HttpClient,
                private httpApp: NisHttpAppService,
                private appService: AppService) {
    }

    public get<T>(url: string, options?: {
                      headers?: HttpHeaders | {
                          [header: string]: string | string[];
                      };
                      context?: HttpContext;
                      observe?: 'body';
                      params?: HttpParams | {
                          [param: string]: string | string[];
                      };
                      reportProgress?: boolean;
                      responseType?: 'json';
                      withCredentials?: boolean;
                  }, doHandleResponse: boolean = true,
                  doHandleError: boolean = true): Observable<T> {
        if (this.platformService.isApp) {
            return new Observable((subscriber: Subscriber<T>) => {
                this.httpApp.get<T>(
                    {
                        requestData: {
                            type: NisHttpRequestType.get,
                            url: this.determineUrl(url),
                            options: options
                        },
                        subscriber: subscriber
                    }, doHandleResponse, doHandleError);
            });
        } else {
            return this.httpClient.get<T>(this.determineUrl(url), options);
        }
    }

    patch<T>(url: string, body: any | null, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        context?: HttpContext;
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<T> {
        if (this.platformService.isApp) {
            return new Observable((subscriber: Subscriber<T>) => {
                this.httpApp.patch(
                    {
                        requestData: {
                            type: NisHttpRequestType.patch,
                            url: this.determineUrl(url),
                            body: body,
                            options: options
                        },
                        subscriber: subscriber
                    });
            });
        } else {
            return this.httpClient.patch<T>(this.determineUrl(url), body, options);
        }
    }

    public determineUrl(url?: string) {
        if (Utils.isNullOrUndefined(url)) {
            return this.appService.serverUrl + `/${environment.baseUrlSegment}/`;
        } else if (!url.includes('://')) {
            if (url.includes('api/') || url.includes('resource/') || url.includes('.well-known/') ||
                url.includes('apple-app-site-association')) {
                return this.appService.serverUrl + '/' + url;
            } else {
                return this.appService.serverUrl + `/${environment.baseUrlSegment}/` + url;
            }
        } else {
            return url;
        }
    }

    public getBlob(url: string, options: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        context?: HttpContext;
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        body?: any;
        reportProgress?: boolean;
        responseType: 'blob';
        withCredentials?: boolean;
    } = { responseType: 'blob' }): Observable<Blob> {
        if (this.platformService.isApp) {
            return new Observable((subscriber: Subscriber<Blob>) => {
                this.httpApp.getBlob(
                    {
                        requestData: {
                            type: NisHttpRequestType.get,
                            url: this.determineUrl(url),
                            options: options
                        },
                        subscriber: subscriber
                    }, true);
            });
        }
        if (options.body) {
            return this.httpClient.post(this.determineUrl(url), options.body, options);
        }
        return this.httpClient.get(this.determineUrl(url), options);
    }

    public getText(url: string, options: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        context?: HttpContext;
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType: 'text';
        withCredentials?: boolean;
    } = { responseType: 'text' }): Observable<string> {
        if (this.platformService.isCapacitor) {
            return new Observable((subscriber: Subscriber<string>) => {
                this.httpApp.getText(
                    {
                        requestData: {
                            type: NisHttpRequestType.getText,
                            url: this.determineUrl(url),
                            options: options
                        },
                        subscriber: subscriber
                    });
            });
        } else {
            return this.httpClient.get(this.determineUrl(url), options);
        }
    }

    public async performDownloadRequest(url: string, fileName: string) {
        return this.httpApp.download(url, fileName);
    }

    public openFile(url: string) {
        const fileUrl = this.determineUrl(url);
        if (!(this.platformService.isCapacitor)) {
            this.openFileInWindow(fileUrl);
        }
    }

    post<T>(url: string, body: any | null, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        context?: HttpContext;
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }, serializer?: 'json' | 'multipart' | 'urlencoded', doHandleError: boolean = true): Observable<T> {
        if (this.platformService.isApp) {
            return new Observable((subscriber: Subscriber<T>) => {
                this.httpApp.post(
                    {
                        requestData: {
                            type: NisHttpRequestType.post,
                            url: this.determineUrl(url),
                            body: body,
                            options: options,
                            serializer: serializer
                        },
                        subscriber: subscriber
                    }, doHandleError);
            });
        } else {
            return this.httpClient.post<T>(this.determineUrl(url), body, options);
        }
    }

    /**
     * Construct a PUT request which interprets the body as text and returns it.
     *
     * @return an `Observable` of the body as a `string`.
     */
    put<T>(url: string, body: any | null, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        context?: HttpContext;
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<T> {
        if (this.platformService.isApp) {
            return new Observable((subscriber: Subscriber<T>) => {
                this.httpApp.put(
                    {
                        requestData: {
                            type: NisHttpRequestType.put,
                            url: this.determineUrl(url),
                            body: body, options: options
                        },
                        subscriber: subscriber
                    });
            });
        } else {
            return this.httpClient.put<T>(this.determineUrl(url), body, options);
        }
    }

    delete<T>(url: string, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        context?: HttpContext;
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<T> {
        if (this.platformService.isApp) {
            return new Observable((subscriber: Subscriber<T>) => {
                this.httpApp.delete(
                    {
                        requestData: {
                            type: NisHttpRequestType.put,
                            url: this.determineUrl(url),
                            options: options
                        },
                        subscriber: subscriber
                    });
            });
        } else {
            return this.httpClient.delete<T>(this.determineUrl(url), options);
        }
    }


    public downloadAuthenticatedFile(url: string, filename: string, options?: { urlParams?: HttpParams, body?: any }): Observable<Blob> {
        const optionsList: any = {};
        optionsList['responseType'] = 'blob';
        if (options?.urlParams) {
            optionsList['params'] = options.urlParams;
        }
        if (options?.body) {
            optionsList['body'] = options.body;
        }
        return this.handleBlobResponse(this.getBlob(url, optionsList), filename);
    }

    private handleBlobResponse(responseObservable: Observable<Blob>, filename: string) {
        return responseObservable.pipe(
            tap(
                (response: Blob) => {
                    const url: string = URL.createObjectURL(response);
                    this.openAndDownloadFile(url, filename);
                    URL.revokeObjectURL(url);
                })
        );
    }

    private openFileInWindow(url: string) {
        if (this.platformService.isElectron) {
            this.openAndDownloadFile(url);
        } else {
            window.open(url, '_blank');
        }
    }

    private async openAndDownloadFile(urlPath: string, filename?: string) {
        const a = document.createElement('a') as HTMLAnchorElement;
        a.href = urlPath;
        a.style.display = 'none';
        a.target = '_blank';
        if (filename) {
            a.download = filename;
        }
        document.body.appendChild(a);
        a.click();

        // Chrome requires the timeout
        await setTimeout(null, 100);
        a.remove();
    }
}
