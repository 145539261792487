import {Injectable} from '@angular/core';
import {NisApp} from '../../types/nis/nis.types';
import {AppConfigTypes} from '../../types/config/app-config.types';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    public app: NisApp;
    public appConfig: AppConfigTypes;

    private _serverUrl: string = '';

    public get serverUrl(): string {
        return this._serverUrl;
    }

    public set serverUrl(value: string) {
        this._serverUrl = value;
    }

    public get isNxApp(): boolean {
        return this.app === NisApp.nx;
    }

    public get isPaApp(): boolean {
        return this.app === NisApp.pa;
    }

    public get isConnectApp(): boolean {
        return this.app === NisApp.connect;
    }

    public get isAdminApp(): boolean {
        return this.app === NisApp.admin;
    }

    public get clientType(): string {
        if (this.isPaApp) {
            return 'pa';
        } else if (this.isConnectApp) {
            return 'connect';
        } else if (this.isAdminApp) {
            return 'admin';
        }
        return 'nx';
    }
}
