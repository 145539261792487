import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {HttpErrorResponse, HttpHandler, HttpHeaders, HttpRequest} from '@angular/common/http';
import {AppService} from '../app/app.service';

@Injectable({ providedIn: 'root' })
export class AuthInterceptService {
    private readonly authHeaderName: string = 'Authorization';
    private readonly authHeaderValue: string = 'Bearer ';

    constructor(private auth: AuthService, private app: AppService) {
        this.interceptError = this.interceptError.bind(this);
    }

    public async interceptError(err: unknown): Promise<void> {
        if (err instanceof HttpErrorResponse && err.status === 401) {
            await this.auth.logout();
        }
    }

    public async interceptBearerToken(req: HttpRequest<unknown>, next: HttpHandler) {
        if (this.shouldAddBearerToken(req.url)) {
            return this.handleRequestWithTokenHeader(req, next);
        } else {
            return next.handle(req).toPromise();
        }
    }

    public shouldAddBearerToken(url: string): boolean {
        return url.startsWith('/') || url.startsWith(this.app.serverUrl);
    }

    // https://www.typescriptlang.org/docs/handbook/2/conditional-types.html
    public async addBearerToken(headers: HttpHeaders): Promise<HttpHeaders>;
    public async addBearerToken(headers: { [header: string]: string | string[] }): Promise<{ [header: string]: string | string[] }>;
    public async addBearerToken(headers: HttpHeaders | { [header: string]: string | string[] })
        : Promise<HttpHeaders | { [header: string]: string | string[] }> {
        if (headers instanceof HttpHeaders) {
            return this.addBearerTokenToHeader(headers);
        } else {
            const bearerToken = await this.auth.getBearerToken();
            if (bearerToken) {
                headers[this.authHeaderName] = this.authHeaderValue + bearerToken;
            }
            return headers;
        }
    }

    private async addBearerTokenToHeader(headers: HttpHeaders = new HttpHeaders()): Promise<HttpHeaders> {
        const bearerToken = await this.auth.getBearerToken();
        return bearerToken ? headers.set(this.authHeaderName, this.authHeaderValue + bearerToken) : headers;
    }

    private async handleRequestWithTokenHeader(req: HttpRequest<unknown>, next: HttpHandler) {
        const newHeaders = await this.addBearerTokenToHeader(req.headers);
        const reqWithAuth = req.clone({ headers: newHeaders });
        return next.handle(reqWithAuth).toPromise();
    }
}
