import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {AuthInterceptService} from './auth-intercept.service';

@Injectable()
export class AuthenticatedInterceptor implements HttpInterceptor {
    constructor(private authIntercept: AuthInterceptService) {
    }

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return from(this.authIntercept.interceptBearerToken(req, next));
    }
}
