import {DefaultUrlSerializer, UrlTree} from '@angular/router';
import {UrlConstants as uc} from './nis-lib/helpers/url-constants';
import { Injectable } from '@angular/core';

@Injectable()
export class NisUrlSerializer extends DefaultUrlSerializer {
    parse(url: string): UrlTree {
        const urlParts = url.replace(uc.nisRouterOutletSeparator, uc.angularRouterOutletSeparator).split('?');
        url = urlParts[0].replace(uc.nisRouteParamSeparator, uc.angularRouteParamSeparator);
        if (urlParts.length === 2) {
            url += '?' + urlParts[1];
        }
        return super.parse(url);
    }

    serialize(tree: UrlTree): string {
        let url: string = super.serialize(tree);

        const urlParts = url.replace(uc.angularRouterOutletSeparator, uc.nisRouterOutletSeparator).split('?');
        url = urlParts[0].replace(uc.angularRouteParamSeparator, uc.nisRouteParamSeparator);
        if (urlParts.length === 2) {
            url += '?' + urlParts[1];
        }
        return url;
    }
}
