import {ToolService} from '../../services/tool/tool.service';
import {ClientType, NisApp, NisModule, NisUserPermission, WorldType} from '../nis/nis.types';
import {ActivatedRoute, Params} from '@angular/router';

export enum ToolState {
    active,
    activeOnMap,
    activeCategory,
    suspended,
    closed,
    disabled,
    hidden
}

export enum ToolPanel {
    mainPanel,
    southPanel,
    adminPanel
}

export enum NxToolCategory {
    search,
    design,
    toolBox,
    result,
    southPanel,
    settings
}

export enum NxTools {
    search = 'search',
    layer = 'layer',
    objectInfo = 'object-info',
    note = 'note',
    survey = 'survey',
    stakeOut = 'stake-out',
    sketch = 'sketch',
    print = 'print',
    dxf = 'dxf',
    measure = 'measure',
    connectNew = 'connect-new',
    connectEdit = 'connect-edit',
    connectBrowser = 'connect-browser',
    trace = 'trace',
    tag = 'tag',
    objectBrowser = 'object-browser',
    avis = 'avis',
    sap = 'sap',
    art = 'art',
    settings = 'settings',
    planauskunft = 'planauskunft',
    netMonitoring = 'net-monitoring',
    design = 'design',
    workflow = 'workflow',
    meterData = 'meter-data',
    ownerList = 'owner-list'
}

export enum AdminToolCategory {
    none = '',
    userManagement = 'nis.admin.user-management',
    planauskunft = 'nis.admin.planauskunft',
    groupPermissions = 'nis.admin.group-permissions',
    layers = 'nis.admin.layers'
}

export enum AdminTools {
    user = 'user-management/user',
    userGroups = 'user-management/user-groups',
    mapExtents = 'group-permission/map-extents',
    layerSets = 'group-permission/layer-sets',
    objectCollections = 'group-permission/object-collections',
    plotLayouts = 'group-permission/plot-layouts',
    dxfConfigurations = 'group-permission/dxf-configurations',
    projectContainerTypes = 'group-permission/project-container-types',
    themes = 'layers/themes',
    layerTree = 'layers/layer-tree',
    externalLayers = 'layers/external-layers',
    externalUrls = 'layers/external-urls',
    smwLayers = 'layers/smw-layers',
    paOrders = 'planauskunft/orders'
}

export type ToolCategory = NxToolCategory | AdminToolCategory;
export type Tools = NxTools | AdminTools;

export interface ToolConfigInterface {
    title: string;
    url?: string;
    icon?: string;
    nisIcon?: string;
    category: ToolCategory;
    initialState?: ToolState;
    enabledState?: ToolState;
    initialContentUrl?: string;
    closeable?: boolean;
    toolService?: ToolService;
    resultService?: ResultService;
    modules?: NisModule | NisModule[];
    userPermission?: NisUserPermission;
    pro?: boolean;
    availableInApp?: NisApp[];
    availableInWorlds?: WorldType[];
    availableInClients?: ClientType[];
    isOfflineAvailable?: boolean;
    subMenuOpenTimeout?: number;
    subMenuCloseTimeout?: number;
}

export interface ToolData {

    /**
     * State of the Tool.
     */
    state: ToolState;

    /**
     * The route of the currently active ContentPage of the tool.
     */
    route?: ActivatedRoute;
    /**
     * The last query Params before leaving world
     */
    queryParams?: Params;
}

export class DefaultToolData implements ToolData {
    state: ToolState;
}

export class ToolSubMenuData {
    constructor(public tool: ToolConfigInterface,
                public sourceElement: HTMLElement,
                public hideDelay: number) {
    }
}

export interface ResultStateChange {
    tools: Tools;
    state: ToolState;
}

export interface ResultService {
    activate: (state: ToolState) => Promise<boolean>;
    suspend: (state: ToolState) => Promise<boolean>;
    close: (state: ToolState) => Promise<boolean>;
}
