export class UrlConstants {
    public static readonly angularRouterOutletSeparator: string = '//';
    public static readonly nisRouterOutletSeparator: string = '/-/';

    public static readonly angularRouteParamSeparator: string = ';';
    public static readonly nisRouteParamSeparator: string = ',';

    public static readonly primaryRouterOutletName: string = 'primary';
    public static readonly southRouterOutletName: string = 'south';
}
