export type RGBA = [number, number, number, number];

export enum IonicDefaultColor {
    primary = 'primary',
    secondary = 'secondary',
    tertiary = 'tertiary',
    success = 'success',
    warning = 'warning',
    danger = 'danger',
    light = 'light',
    medium = 'medium',
    dark = 'dark'
}
