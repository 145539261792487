import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Utils} from '../../helpers/utils';
import {HttpParams} from '@angular/common/http';
import {ProjectContainer} from '../../types/design/project-container.types';

@Injectable({
    providedIn: 'root'
})
export class ProjectContainerService {
    public projectContainerChanged: Subject<string> = new Subject<string>();

    private _activeProjectContainer: ProjectContainer;
    private _activeProjectContainerUrn: string;
    private _activeProjectContainerType: string;

    private projectContainerUrnParamName = 'project_container_urn';
    private projectContainerTypeParamName = 'project_container_type';

    public get activeProjectContainer(): ProjectContainer {
        return this._activeProjectContainer;
    }

    private set activeProjectContainer(projectContainer: ProjectContainer) {
        this._activeProjectContainer = projectContainer;
    }

    public get activeProjectContainerUrn(): string {
        return this._activeProjectContainerUrn;
    }

    private set activeProjectContainerUrn(urn: string) {
        this._activeProjectContainerUrn = urn;
    }

    public get activeProjectContainerType(): string {
        return this._activeProjectContainerType;
    }

    private set activeProjectContainerType(key: string) {
        this._activeProjectContainerType = key;
    }

    public setProjectContainer(projectContainer: ProjectContainer): void {
        this.activeProjectContainer = projectContainer;
        this.activeProjectContainerType = projectContainer?.type.key;

        if (projectContainer?.urn !== this.activeProjectContainerUrn) {
            this.activeProjectContainerUrn = projectContainer?.urn;
            this.projectContainerChanged.next(this.activeProjectContainerUrn);
        }
    }

    public resetProjectContainer(): void {
        this.setProjectContainer(undefined);
    }

    public isProjectContainerOpen(): boolean {
        return !!this.activeProjectContainerUrn;
    }

    public isActiveProjectContainer(urn: string): boolean {
        return this.isProjectContainerOpen() && this.activeProjectContainerUrn === urn;
    }

    public getAdditionalParamsArray(): Record<string, any> {
        const additionalParams: Record<string, any> = {};
        if (this.isProjectContainerOpen()) {
            additionalParams[this.projectContainerUrnParamName] = this.activeProjectContainerUrn;
            additionalParams[this.projectContainerTypeParamName] = this.activeProjectContainerType;
        }
        return additionalParams;
    }

    public getAdditionalParamsString(url: string): string {
        let queryParamsExtension: string;
        if (this.isProjectContainerOpen()) {
            const queryParams = Utils.getQueryParams(url);
            queryParamsExtension = `${this.projectContainerTypeParamName}=${this.activeProjectContainerType}` +
                `&${this.projectContainerUrnParamName}=${this.activeProjectContainerUrn}`;
            if (!Object.getOwnPropertyNames(queryParams).length) {
                queryParamsExtension = '?' + queryParamsExtension;
            } else {
                queryParamsExtension = '&' + queryParamsExtension;
            }
        }
        return queryParamsExtension;
    }

    public appendProjectContainerParams(params: HttpParams): HttpParams {
        return params
            .append(this.projectContainerUrnParamName, this.activeProjectContainerUrn)
            .append(this.projectContainerTypeParamName, this.activeProjectContainerType);
    }

    public includesProjectContainerParams(params: HttpParams): boolean {
        return params.has(this.projectContainerUrnParamName) || params.has(this.projectContainerTypeParamName);
    }
}
