import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {ResponsiveDirective} from './responsive/responsive.directive';
import {DecimalNumberDirective} from './forms/decimal-number.directive';
import {UploadDirective} from './upload/upload.popover.directive';
import {ToolStateDirective} from './tool-state/tool-state.directive';
import {LongPressDirective} from './long-press/long-press.directive';
import {PanDirective} from './pan/pan.directive';
import {PermissionDirective} from './permission/permission.directive';
import {UserPermissionDirective} from './permission/user-permission.directive';
import {ModuleDirective} from './modules/module.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule
    ],
    declarations: [
        ResponsiveDirective,
        DecimalNumberDirective,
        UploadDirective,
        ToolStateDirective,
        LongPressDirective,
        PanDirective,
        PermissionDirective,
        UserPermissionDirective,
        ModuleDirective
    ],
    exports: [
        ResponsiveDirective,
        DecimalNumberDirective,
        UploadDirective,
        ToolStateDirective,
        LongPressDirective,
        PanDirective,
        PermissionDirective,
        UserPermissionDirective,
        ModuleDirective
    ]
})
export class DirectivesModule {
}
