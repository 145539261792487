<!-- Start: App-Loading Screen. -->
<div class="m-app-loading">
    <!-- BEGIN: Actual animated container. -->
    <div class="animated-container">
        <div class="messaging">
            <span class="spinner-loader"></span>
            <br>
            <span style="font-size: 1.3em; font-weight:bold">NisXplorer</span><br>
            <div>{{"nis.loading.clientconfig.message" | translate}}</div>
        </div>
    </div>
    <!-- END: Actual animated container. -->
</div>
<!-- END: App-Loading Screen. -->