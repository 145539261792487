import {Injector} from '@angular/core';

export class ServiceLocator {
    static rootInjector: Injector;
    static nxInjector: Injector;
    static adminInjector: Injector;

    static get injector(): Injector {
        switch (true) {
            case !!this.nxInjector:
                return this.nxInjector;
            case !!this.adminInjector:
                return this.adminInjector;
            default:
                return this.rootInjector;
        }
    }
}
