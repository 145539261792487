import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor(private storage: Storage) {
        this.init();
    }

    async init() {
        const storage = await this.storage.create();
        this.storage = storage;
    }

    public set(key: string, value: any) {
        this.storage?.set(key, value);
    }

    public get(key: string) {
        return this.storage?.get(key);
    }

    public remove(key: string) {
        return this.storage?.remove(key);
    }

    public getKeys() {
        return this.storage?.keys();
    }
}
