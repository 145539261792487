import {Injectable} from '@angular/core';
import {NisHttpWrapperService} from '../http/nis-http-wrapper.service';
import {ApiInfo, ApiVersion} from '../../types/api/api.types';
import {Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiService {

    constructor(private http: NisHttpWrapperService) {
    }

    async getApiVersion(serverAddress: string): Promise<ApiVersion> | null {
        const apiInfo: ApiInfo = await this.fetchActuatorInfo(serverAddress) || await this.fetchAppCompatibility(serverAddress);
        if (!apiInfo) {
            return null;
        }
        const apiVersion: ApiVersion = this.formatApiVersion(apiInfo);
        return apiVersion?.major && apiVersion?.minor && apiVersion?.patch ? apiVersion : null;
    }

    private async fetchApiInfo(serverAddress: string, path: string): Promise<ApiInfo> {
        const apiInfo: Observable<ApiInfo> = this.http.get<ApiInfo>(serverAddress + path, {}, true, false);
        return apiInfo ? await apiInfo.toPromise() : null;
    }

    private formatApiVersion(apiInfo: ApiInfo): ApiVersion | null {
        const apiVersion: string = apiInfo.version;
        if (!apiVersion) {
            return null;
        }
        const [major, minor, ...patchParts] = apiVersion.split('.');
        const patch: string = patchParts.join('.');
        return { major, minor, patch };
    }

    private async fetchActuatorInfo(serverAddress: string): Promise<ApiInfo> {
        return this.fetchApiInfo(serverAddress, '/actuator/info');
    }

    // For 2.7 compatibility
    private async fetchAppCompatibility(serverAddress: string): Promise<ApiInfo> {
        return this.fetchApiInfo(serverAddress, '/GSS-Web-NIS/app/compatibility');
    }
}
