import {Injectable, NgZone} from '@angular/core';

import {AuthConnect, AuthResult, ProviderOptions, TokenType} from '@ionic-enterprise/auth';
import {PlatformService} from '../platform/platform.service';
import {KeycloakProvider} from './keycloak.provider';
import {Subject} from 'rxjs';
import {VaultService} from '../../storage/vault.service';
// eslint-disable-next-line boundaries/no-ignored
import {environment} from '../../../../environments/environment';
import {DeeplinkService} from '../deeplink/deeplink.service';
import {StorageService} from '../../storage/storage.service';
import {ParamMap, Router} from '@angular/router';
import {AppService} from '../app/app.service';
import {NisAccessToken} from '../../types/auth/auth.types';
import {FormControl, FormGroup} from '@angular/forms';
import {ElectronService} from '../electron/electron.service';
import {NisApp} from '../../types/nis/nis.types';

@Injectable({ providedIn: 'root' })
export class AuthService {
    public loginForm: FormGroup = new FormGroup({
        serverAddress: new FormControl('')
    });
    public onLogout: Subject<void> = new Subject<void>();
    private initializing: Promise<void> | undefined;
    private authOptions: ProviderOptions;
    private openIdConfigPath: string;
    private readonly provider: KeycloakProvider;

    constructor(private appService: AppService,
                private electronService: ElectronService,
                private ngZone: NgZone,
                private platformService: PlatformService,
                private vault: VaultService,
                private storage: StorageService,
                private router: Router,
                private deeplinkService: DeeplinkService
    ) {
        this.provider = new KeycloakProvider();
    }

    public async init(providerOptions: ProviderOptions, openIdConfigPath: string, keycloakUrl: string): Promise<void> {
        await this.initialize();
        this.authOptions = providerOptions;
        this.openIdConfigPath = openIdConfigPath;
        if (this.platformService.isCapacitor) {
            this.storage.get('serverAddress').then((serverAddress) => {
                this.updateServerAddress(serverAddress);
            });
        } else if (this.platformService.isElectron) {
            this.electronService.getServerName().then((serverAddress) => {
                this.updateServerAddress(serverAddress);
            });
        } else {
            this.changeKeycloakUrl(keycloakUrl);
        }
    }

    public changeKeycloakUrl(newUrl: string): void {
        if (!this.authOptions) {
            return;
        }
        const keycloakPrefix: string = this.platformService.isApp ? '/keycloak' : '';
        this.authOptions.discoveryUrl = newUrl + keycloakPrefix + this.openIdConfigPath;
        this.authOptions.redirectUri = this.getRedirectUri();
    }

    public async login(redirectUri?: string, isAuthorizationFailure: boolean = false): Promise<void> {
        const authOptions: ProviderOptions = { ...this.authOptions };
        if (redirectUri) {
            authOptions.redirectUri = redirectUri;
        }
        this.provider.isAuthorizationFailure = isAuthorizationFailure;
        const authResult: AuthResult = await AuthConnect.login(this.provider, authOptions);
        await this.saveAuthResult(authResult);
        if (this.platformService.isApp) {
            await this.navigateToRequestedUrl();
        }
    }

    public async logout(notAuthorized: boolean = false): Promise<void> {
        let authResult: AuthResult = await this.getAuthResultAndRefreshIfNecessary();
        if (!authResult) {
            authResult = await this.vault.getSession();
        }
        let logoutUrl = this.getLogoutUrl(notAuthorized);
        if (authResult) {
            authResult.provider.options.logoutUrl = logoutUrl;
            // this has to be before logout for web
            await this.saveAuthResult(null);
            await AuthConnect.logout(this.provider, authResult);
        }
        if (this.platformService.isCapacitor) {
            logoutUrl = window.location.origin + '/' + environment.baseUrlSegment + '/'
                + NisApp.nx + '/mobile-landing-page' + (notAuthorized ? '?authorization_failure=true' : '');
        }
        // use window.location to reload angular (like a page reload in a browser)
        // otherwise all components and service will not be destroyed and have a lot of cached data
        window.location.replace(logoutUrl);
    }

    public async resumeMobileSession(authResult: AuthResult): Promise<void> {
        if (this.platformService.isApp) {
            const newAuthResult: AuthResult = await this.refreshAuth(authResult);
            if (newAuthResult) {
                await this.navigateToRequestedUrl();
            }
        }
    }

    public async isAuthenticated(): Promise<boolean> {
        await this.initialize();
        const authResult: AuthResult = await this.getAuthResultAndRefreshIfNecessary();
        if (!authResult) {
            return false;
        }

        const isAccessTokenAvailable: boolean = await AuthConnect.isAccessTokenAvailable(authResult);
        if (isAccessTokenAvailable && !(await AuthConnect.isAccessTokenExpired(authResult))) {
            return true;
        }

        try {
            const refreshedAuthResult: AuthResult = await AuthConnect.refreshSession(this.provider, authResult);
            await this.saveAuthResult(refreshedAuthResult);
            return true;
        } catch (err) {
            // Refresh failed, or no `refresh_token` available
            await this.saveAuthResult(null);
            return false;
        }
    }

    public async getBearerToken(): Promise<string | null> {
        await this.initialize();
        const authResult: AuthResult = await this.getAuthResultAndRefreshIfNecessary();
        return authResult?.accessToken;
    }

    public async getRoles(): Promise<string[]> {
        const authResult: AuthResult = await this.getAuthResultAndRefreshIfNecessary();
        const data: NisAccessToken = await AuthConnect.decodeToken<NisAccessToken>(TokenType.access, authResult);
        return data?.realm_access?.roles ?? [];
    }

    public getServerAddress(): string | undefined {
        if (this.loginForm.contains('serverAddress')) {
            return this.loginForm.get('serverAddress').value.toString().trim().replace(/^(.*\/\/[^/?#]*).*$/, '$1');
        }
    }

    public setServerAddress(serverAddress: string) {
        this.appService.serverUrl = serverAddress;
        if (this.platformService.isCapacitor) {
            this.storage.set('serverAddress', serverAddress);
        } else if (this.platformService.isElectron) {
            this.electronService.ipcRenderer.invoke('store-setServerName', serverAddress);
        }
        this.changeKeycloakUrl(serverAddress);
    }

    private async refreshAuth(authResult: AuthResult): Promise<AuthResult | null> {
        let newAuthResult: AuthResult | null = null;
        if (await AuthConnect.isRefreshTokenAvailable(authResult)) {
            try {
                newAuthResult = await AuthConnect.refreshSession(this.provider, authResult);
            } catch (err) {
                return null;
            }
            await this.saveAuthResult(newAuthResult);
        }
        return newAuthResult;
    }

    private async getAuthResultAndRefreshIfNecessary(): Promise<AuthResult | null> {
        let authResult: AuthResult = await this.vault.getSession();
        if (!!authResult && await AuthConnect.isAccessTokenAvailable(authResult) && await AuthConnect.isAccessTokenExpired(authResult)) {
            authResult = await this.refreshAuth(authResult);
        }
        return authResult;
    }

    private onAuthChange(isAuthenticated: boolean): void {
        if (!isAuthenticated) {
            this.ngZone.run((): void => {
                this.onLogout.next();
            });
        }
    }

    private async saveAuthResult(authResult: AuthResult | null): Promise<void> {
        if (authResult) {
            await this.vault.setSession(authResult);
        } else {
            await this.vault.clear();
        }
        this.onAuthChange(!!authResult);
    }

    private initialize(): Promise<void> {
        if (!this.initializing) {
            this.initializing = new Promise(resolve => {
                this.setup().then(() => resolve());
            });
        }
        return this.initializing;
    }

    public async handleWebLogin(params: ParamMap): Promise<void> {
        let authResult: AuthResult | null = null;
        const entriesMap = new Map<string, string>();
        params.keys.forEach((key) => {
            entriesMap.set(key, params.get(key));
        });
        if (entriesMap.size > 0) {
            const queryEntries = Object.fromEntries(entriesMap);
            try {
                authResult = await AuthConnect.handleLoginCallback(queryEntries, this.authOptions);
            } catch (err: any) {
                await this.router.navigate(['/']);
                return;
            }
        }
        await this.saveAuthResult(authResult);
    }

    public async handleElectronAuthCallback(paramMap: ParamMap): Promise<void> {
        await this.handleWebLogin(paramMap);
        await this.router.navigate(['/']);
    }

    private async navigateToRequestedUrl() {
        this.provider.isAuthorizationFailure = false;
        const lastUrl = await this.storage.get(this.deeplinkService.storageEntry);
        if (lastUrl) {
            await this.router.navigateByUrl(lastUrl);
            await this.storage.remove(this.deeplinkService.storageEntry);
        } else {
            await this.router.navigateByUrl('/' + this.appService.app);
        }
    }

    private getRedirectUri(): string {
        switch (this.platformService.getPlatform()) {
            case 'android':
            case 'ios':
                return 'ch.nis.xplorer://nx';
            case 'electron':
                return 'http://localhost/electron-keycloak-redirect';
            default:
                return window.location.origin + '/' + environment.baseUrlSegment;
        }
    }

    private getLogoutUrl(isAuthFailure: boolean): string {
        return this.getRedirectUri() + '/' + this.appService.app + (isAuthFailure ? '?authorization_failure=true' : '');
    }

    private updateServerAddress(serverAddress: string): void {
        if (serverAddress) {
            this.appService.serverUrl = serverAddress;
            this.changeKeycloakUrl(this.appService.serverUrl);
            this.loginForm.patchValue({
                serverAddress: this.appService.serverUrl
            });
        }
    }

    private setup(): Promise<void> {
        return AuthConnect.setup({
            platform: this.platformService.isCapacitor ? 'capacitor' : 'web',
            logLevel: 'NONE',
            ios: {
                webView: 'private'
            },
            web: {
                uiMode: 'current',
                authFlow: 'PKCE'
            }
        });
    }
}
