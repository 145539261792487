import {Route, UrlMatchResult, UrlSegment, UrlSegmentGroup} from '@angular/router';
import {NisApp} from '../types/nis/nis.types';

export const nxMatcher = (segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult =>
    checkNisApps([NisApp.nx, NisApp.pa, NisApp.connect], route, segments);

export const adminMatcher = (segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult =>
    checkNisApps([NisApp.admin], route, segments);

const checkNisApps = (nisApps: NisApp[], route: Route, segments: UrlSegment[]): UrlMatchResult => {
    if (segments.length === 0) {
        return null;
    }
    const path = segments[0].path;
    for (const nisApp of nisApps) {
        if (path === nisApp) {
            return { consumed: [] };
        }
    }
    return null;
};
