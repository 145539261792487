import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthInterceptService} from './auth-intercept.service';

@Injectable()
export class UnauthenticatedInterceptor implements HttpInterceptor {
    constructor(private authIntercept: AuthInterceptService) {
    }

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            tap({
                error: this.authIntercept.interceptError
            })
        );
    }
}
