import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
// eslint-disable-next-line boundaries/no-ignored
import {environment} from '../../../../environments/environment';
import {NisKeycloakConfig} from '../../types/auth/auth.types';


@Injectable({
    providedIn: 'root'
})
export class AuthInitConfigService {
    private config: NisKeycloakConfig;

    constructor(private httpClient: HttpClient) {
    }

    public getConfig(): Observable<NisKeycloakConfig | null> {
        return this.httpClient
            .get(this.getConfigFile(), {
                observe: 'response'
            })
            .pipe(
                catchError((error) => {
                    console.log(error);
                    return of(null);
                }),
                mergeMap((response) => {
                    if (response && response.body) {
                        this.config = response.body;
                        return of<NisKeycloakConfig>(this.config);
                    } else {
                        return of(null);
                    }
                }));
    }

    private getConfigFile(): string {
        return environment.configFile;
    }
}
