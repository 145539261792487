import {AuthConnectConfig, AuthProvider, AuthResult, Manifest, Params, ProviderOptions, ProviderURLInfo} from '@ionic-enterprise/auth';

export class KeycloakProvider extends AuthProvider {

    public isAuthorizationFailure: boolean = false;

    async authorizeRequest(manifest: Manifest,
                           options: ProviderOptions,
                           config: Pick<AuthConnectConfig, 'ios' | 'android' | 'web' | 'platform' | 'logLevel'>
    ): Promise<ProviderURLInfo> {
        if (!this.usePKCE(config)) {
            throw new Error('Only PKCE is supported!');
        }
        await this.checkOrGenerateKeys(config);
        const url = manifest['authorization_endpoint'];
        const params: Params = {};
        params['client_id'] = options.clientId;
        params['redirect_uri'] = options.redirectUri;
        params['scope'] = options.scope;
        params['code_challenge_method'] = 'S256';
        params['code_challenge'] = this.key.challenge;
        params['response_type'] = 'code';
        if (this.isAuthorizationFailure) {
            params['authorization_failure'] = '';
        }
        return { url, params };
    }

    async tokenRequest(manifest: Manifest,
                       options: ProviderOptions,
                       config: Pick<AuthConnectConfig, 'ios' | 'android' | 'web' | 'platform' | 'logLevel'>
    ): Promise<ProviderURLInfo> {
        if (!this.usePKCE(config)) {
            throw new Error('Only PKCE is supported!');
        }
        await this.checkOrGenerateKeys(config);
        const url = manifest['token_endpoint'];
        const payload: Params = {};
        payload['client_id'] = options.clientId;
        payload['redirect_uri'] = options.redirectUri;
        payload['grant_type'] = 'authorization_code';
        payload['code_verifier'] = this.key.verifier;
        payload['token_format'] = 'jwt';
        return { url, payload, tokenCodeName: 'code' };
    }

    async refreshTokenRequest(auth?: AuthResult): Promise<ProviderURLInfo> {
        if (!auth) {
            throw new Error('auth result is missing');
        }
        const info: ProviderURLInfo = await this.tokenRequest(auth.provider.manifest, auth.provider.options, auth.config);
        if (!info.payload) {
            info.payload = {};
        }
        info.payload['grant_type'] = 'refresh_token';
        info.tokenCodeName = 'refresh_token';
        return info;
    }

    async logoutRequest(auth?: AuthResult): Promise<ProviderURLInfo> {
        if (!auth) {
            throw new Error('auth result is missing');
        }
        await this.checkOrGenerateKeys(auth.config);
        const url = auth.provider.manifest['end_session_endpoint'];
        const params = {};
        params['client_id'] = auth.provider.options.clientId;
        params['post_logout_redirect_uri'] = auth.provider.options.logoutUrl;
        params['id_token_hint'] = auth.idToken ?? '';
        return { url, params };
    }
}
