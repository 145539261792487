import {Injectable} from '@angular/core';
import * as LiveUpdates from '@capacitor/live-updates';
import {MessageService} from '../message/message.service';
import {ToastService} from '../toast/toast.service';
import {AlertMessageButton} from '../../types/general/popup.types';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {App, AppInfo} from '@capacitor/app';
import {AppLauncher} from '@capacitor/app-launcher';
import {NisHttpWrapperService} from '../http/nis-http-wrapper.service';
import {PlatformService} from '../platform/platform.service';

@Injectable({ providedIn: 'root' })
export class LiveUpdateService {
    public updateCompleted: Subject<boolean> = new Subject<boolean>();
    private readonly syncTimeoutInMS: number = 2000;
    private readonly iosAppLink: string = 'itms-apps://itunes.apple.com/ch/app/nisxplorer-mobile-2/id1458104823?mt=8';
    private readonly androidAppLink: string = 'https://play.google.com/store/apps/details?id=ch.nis.xplorer';

    constructor(private http: NisHttpWrapperService,
                private messageService: MessageService,
                private platformService: PlatformService,
                private toastService: ToastService,
                private translateService: TranslateService) {
    }

    async doLiveUpdate(channelName: string): Promise<void> {
        let timeoutId: NodeJS.Timeout;
        try {
            await LiveUpdates.setConfig({ channel: channelName });

            // Set a timeout to show a message if LiveUpdates.sync takes more than 1 seconds
            timeoutId = setTimeout(() => {
                this.toastService.presentTranslatedToast('nis.login.app_outdated', null, null);
            }, this.syncTimeoutInMS);

            const result: LiveUpdates.SyncResult = await LiveUpdates.sync();

            // Clear the timeout if LiveUpdates.sync has completed
            clearTimeout(timeoutId);

            if (result.source === 'download') {
                await LiveUpdates.reload();
            } else if (await this.isIncompatibleUpdateAvailable(channelName)) {
                await this.showAppCompatibilityMessage();
            } else {
                this.updateCompleted.next(true);
            }
        } catch (e) {
            console.error(e);
            this.handleLiveUpdateError(channelName);
        } finally {
            // Ensure the timeout is cleared in case of any error
            clearTimeout(timeoutId);
            await this.toastService.dismissToast();
        }
    }

    private handleLiveUpdateError(channelName: string): void {
        const retryButton: AlertMessageButton = new AlertMessageButton(this.translateService.instant('nis.retry'), () => {
            this.doLiveUpdate(channelName);
        });
        const ignoreButton: AlertMessageButton = new AlertMessageButton(this.translateService.instant('nis.continue_anyway'),
            () => this.updateCompleted.next(true));
        const alertButtons: AlertMessageButton[] = [retryButton];
        // User should not be able to ignore the update if the channel is 2.7 but the app's snapshot is still 3.x
        if (channelName !== '2.7') {
            alertButtons.push(ignoreButton);
        }
        this.messageService.displayMessage('nis.live_update.failed.text', 'nis.live_update.failed.title', alertButtons, [], false);
    }

    private async isIncompatibleUpdateAvailable(channelName: string): Promise<boolean> | null {
        const appInfo: AppInfo = await App.getInfo();
        const body = {
            'channel_name': channelName,
            'app_id': '8f86d8a5',
            'device': { 'binary_version': appInfo.version, 'platform': this.platformService.getPlatform() }
        };
        let incompatibleUpdateAvailable: boolean = false;
        const response: any = await this.http.post('https://api.ionicjs.com/apps/8f86d8a5/channels/check-device', body, {}, 'json',
            false).toPromise();
        if (response && response.data) {
            incompatibleUpdateAvailable = response.data.incompatibleUpdateAvailable;
        }
        return incompatibleUpdateAvailable;
    }

    private async showAppCompatibilityMessage() {
        const appLink = this.getAppLink();
        if (appLink) {
            const forwardToStoreButton = new AlertMessageButton(this.translateService.instant('nis.update'),
                () => {
                    this.updateCompleted.next(false);
                    AppLauncher.openUrl({ url: appLink });
                });
            const continueButton = new AlertMessageButton(this.translateService.instant('nis.continue_anyway'),
                () => this.updateCompleted.next(true));
            await this.messageService.displayMessage(this.translateService.instant('nis.login.compatibility_message', {}),
                'nis.login.compatibility_title', [forwardToStoreButton, continueButton], [], false);
        }
    }

    private getAppLink(): string | null {
        if (this.platformService.isIOS) {
            return this.iosAppLink;
        } else if (this.platformService.isAndroid) {
            return this.androidAppLink;
        }
        return null;
    }
}
