import {Injectable} from '@angular/core';
import {Keyboard, KeyboardPlugin} from '@capacitor/keyboard';
import {Utils} from '../../helpers/utils';
import {Deferred} from '../../helpers/deferred';
import {PlatformService} from '../platform/platform.service';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class KeyboardService {
    private isVisible: boolean = false;
    private keyboard: KeyboardPlugin;
    public keyboardDidShow: Subject<void> = new Subject<void>();
    public keyboardDidHide: Subject<void> = new Subject<void>();

    constructor(private platformService: PlatformService) {
        if (this.platformService.isCapacitor) {
            this.keyboard = Keyboard;

            this.keyboard.addListener('keyboardDidShow', (info: any) => {
                this.isVisible = true;
                this.keyboardDidShow.next()
            });

            this.keyboard.addListener('keyboardDidHide', () => {
                this.isVisible = false;
                this.keyboardDidHide.next()
            });
        }
    }

    public get isKeyboardVisible(): boolean {
        return this.isVisible;
    }

    public waitForKeyboardHide(): Promise<void> {
        const deferred: Deferred<void> = new Deferred<void>();
        Utils.waitFor(this, () => !this.isKeyboardVisible, () => {
            setTimeout(() => {
                deferred.resolve();
            });
        });
        return deferred.promise;
    }

    public waitForKeyboardShow(): Promise<void> {
        const deferred: Deferred<void> = new Deferred<void>();
        Utils.waitFor(this, () => this.isKeyboardVisible, () => {
            setTimeout(() => {
                deferred.resolve();
            });
        });
        return deferred.promise;
    }

    public hideKeyboard(): Promise<void> {
        if (this.keyboard) {
            return this.keyboard.hide();
        }
        return Promise.resolve()
    }

    public showKeyboard(): Promise<void> {
        if (this.keyboard) {
            return this.keyboard.show();
        }
        return Promise.resolve()
    }
}
