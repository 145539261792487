import {Params} from '@angular/router';
import {StringKeyValue} from '../types/general/general.types';
import {UrlConstants as uc} from './url-constants';
import {CoordinateTypes} from '../types/coordinate/coordinate.types';
import * as md5 from 'md5';
import {Color} from 'ol/color';
import {NisApp} from '../types/nis/nis.types';
import * as moment from 'moment';

export class Utils {

    public static isNullOrUndefined(value: any): boolean {
        return value === null || value === undefined;
    }

    public static isNumber(value: any) {
        return !isNaN(value) && value !== '';
    }

    public static evaluate(value: string | boolean, ifNullOrUndefined: boolean = false): boolean {
        if (Utils.isNullOrUndefined(value)) {
            return ifNullOrUndefined;
        } else {
            if (typeof value === 'string') {
                return value.toLowerCase() === 'true';
            } else {
                return value;
            }
        }
    }

    public static getValueAsZeroPaddedString(value: string | number, length: number): string {
        return (String(0).repeat(length) + String(value)).slice(String(value).length);
    }

    public static round(value: number, digits: number = 0): number {
        const multiplier = Math.pow(10, digits);
        return Math.round(value * multiplier) / multiplier;
    }

    public static convertLength(value: number, destType: string, srcType: string): number {
        if (value !== null && !isNaN(Number(value))) {
            const modifiedDestType = destType.slice(0, -1);
            const modifiedSrcType = srcType.slice(0, -1);
            return this.convertSi('m', value, modifiedDestType, modifiedSrcType);
        }
    }

    public static convertSi(baseUnit: string, value: number, destType: string, srcType: string): number {
        // Alternative: https://www.npmjs.com/package/convert-units (but seems to be quite big module incl. lodash)
        // example: return convert(value).from(srcType + baseUnit).to(destType + baseUnit).value();

        if (value !== null && !isNaN(Number(value))) {
            const unitConversionFraction: [number, number] = [1, 1];

            const fromSrcToBase = this.getFractionsToBaseUnit(srcType);
            unitConversionFraction[0] *= fromSrcToBase[0];
            unitConversionFraction[1] *= fromSrcToBase[1];
            const fromDestToBase = this.getFractionsToBaseUnit(destType);
            unitConversionFraction[0] *= fromDestToBase[1];
            unitConversionFraction[1] *= fromDestToBase[0];

            return value * unitConversionFraction[0] / unitConversionFraction[1];
        }
    }

    public static convertToNumber(coordinate: any, unitConversionMultiplier: number = 1): CoordinateTypes {
        if (coordinate[0] === 'X') {
            return null;
        }
        if (typeof coordinate[0] !== 'number') {
            if (coordinate.length > 0 && typeof coordinate[0] === 'string') {
                if (coordinate[0].includes(',')) {
                    return (coordinate).map(
                        (coord: string) => Utils.convertToNumber(coord.split(','), unitConversionMultiplier));
                } else {
                    return (coordinate).map((coord: string) => Number(coord) * unitConversionMultiplier);
                }
            } else {
                return (coordinate).map((coord: any) => Utils.convertToNumber(coord, unitConversionMultiplier));
            }
        } else {
            return coordinate.map((coord: number) => coord * unitConversionMultiplier);
        }
    }

    public static convertToStringArray(inputArray: any): any[] {
        if (!inputArray || !inputArray.length) {
            return null;
        }
        let outputArray: any[] = [];

        if (Array.isArray(inputArray[0])) {
            outputArray = (inputArray).map((e: any) => Utils.convertToStringArray(e));
        } else {
            return inputArray.map(String);
        }

        return outputArray;
    }

    public static waitFor(thisContext: any, condition: () => boolean, callback: () => void,
                          waitFunction: () => void = (): void => undefined, interval: number = 100) {
        if (!condition.call(thisContext)) {
            waitFunction.call(thisContext);
            setTimeout(Utils.waitFor.bind(thisContext, thisContext, condition, callback, waitFunction, interval),
                interval);
        } else {
            callback.call(thisContext);
        }
    }

    public static escapeRegexp(queryToEscape: string): string {
        return queryToEscape.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    }

    public static stripTags(input: string): string {
        const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
        const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
        return input.replace(commentsAndPhpTags, '').replace(tags, '');
    }

    public static getQueryParams(url: string): Params {
        const searchParamMap: Params = {};
        if (url.indexOf('?') === -1) {
            return searchParamMap;
        }
        const mapParamsString: string = url.substring(url.indexOf('?') + 1);
        if (mapParamsString) {
            const mapParams: string[] = mapParamsString.split('&');
            for (const mapParam of mapParams) {
                const keyValue = mapParam.split('=');
                searchParamMap[keyValue[0]] = keyValue[1];
            }
        }
        return searchParamMap;
    }

    public static getHash(s: string): string {
        return md5(s);
    }

    static getRandomNumber(maxValue: number = 1000000): number {
        return Math.floor(Math.random() * maxValue) + 1;
    }

    /**
     * Turn a [key,value] array into an object
     *
     * @returns Object
     */
    public static objectFromEntries(iterable: string[][]): StringKeyValue<string> {
        return [...iterable].reduce((obj, [key, val]) => {
            if (val) {
                obj[key] = val;
            }
            return obj;
        }, {});
    }

    public static dictionaryFromRouteParameterString(routeParams: string): { [key: string]: string } {
        const dictToReturn = {};
        const keyValues: string[] = routeParams.split(uc.nisRouteParamSeparator);
        for (const keyValuePair of keyValues) {
            const splitPair = keyValuePair.split('=');
            if (splitPair.length === 2) {
                dictToReturn[splitPair[0]] = splitPair[1];
            }
        }

        return dictToReturn;
    }

    public static logError(error: any): void {
        let errorMessage: string;
        if (typeof error === 'string') {
            errorMessage = error;
        } else {
            errorMessage = error.message;
        }
        console.error(errorMessage);
    }

    /**
     * Check if objects have same property-values
     *
     * @returns boolean
     */
    public static isEquivalent(a: any, b: any): boolean {
        if (typeof a !== 'object' && typeof b !== 'object') {
            return a === b;
        } else {
            const aProps = Object.getOwnPropertyNames(a);
            const bProps = Object.getOwnPropertyNames(b);

            if (aProps.length !== bProps.length) {
                return false;
            }

            for (const propName of aProps) {
                if (a[propName] !== b[propName]) {
                    return false;
                }
            }

            return true;
        }
    }

    public static colorToHex(color: Color | Uint8Array): string {
        return ('00' + color[0].toString(16)).substr(-2) +
            ('00' + color[1].toString(16)).substr(-2) +
            ('00' + color[2].toString(16)).substr(-2);
    }

    public static degree2rad(degree: number): number {
        return degree / 180 * Math.PI;
    }

    public static string2Number(value: string): number {
        const parsedValue: number = parseFloat(value);
        if (parsedValue) {
            return parsedValue;
        } else {
            return 0;
        }
    }

    public static base64ToBlob(base64String: string, contentType: string): Blob {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: contentType });
    }

    private static getFractionsToBaseUnit(srcType: string) {
        const unitConversionFraction: [number, number] = [1, 1];
        switch (srcType) {
            case 'm': {
                unitConversionFraction[1] *= 10;
            }
            // intentionally fall-through. convert milli to centi and fall-trough to centi case.
            // eslint-disable-next-line no-fallthrough
            case 'c': {
                unitConversionFraction[1] *= 10;
            }
            // eslint-disable-next-line no-fallthrough
            case 'd': {
                unitConversionFraction[1] *= 10;
                // break when we reach baseUnit.
                break;
            }
            case 'M': {
                unitConversionFraction[0] *= 1000;
            }
            // intentionally fall-through. convert Mega to kilo and fall-trough to kilo case.
            // eslint-disable-next-line no-fallthrough
            case 'k': {
                unitConversionFraction[0] *= 1000;
                break;
            }
            default: {
                unitConversionFraction[0] *= 1;
            }
        }
        return unitConversionFraction;
    }

    public static getNisAppFromUrl(url: string): NisApp {
        const appInUrlMatcher: RegExp = /https?:\/\/(?<domain>[^/?#]*\/)(?<basePath>GSS-Web-NIS\/)?(?<app>[^/?#]*)(?<subPath>[/?#].*)?$/g;
        const { app } = appInUrlMatcher.exec(url).groups;
        const appKey: string = Object.keys(NisApp).find((enumKey: string) => enumKey === app || NisApp[enumKey] === app);
        return NisApp[appKey] || NisApp.nx;
    }

    public static addTimestampToFilename(fileNameWithExtension: string): string {
        const i: number = fileNameWithExtension.lastIndexOf('.');
        const fileName: string = fileNameWithExtension.slice(0, i);
        const fileExtension: string = fileNameWithExtension.slice(i, fileNameWithExtension.length);
        return fileName + moment().format('_YYYYMMDD_HHmm') + fileExtension;
    }
}
