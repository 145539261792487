import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';
import {ToastButton, ToastOptions} from '@ionic/core';
import {TranslateService} from '@ngx-translate/core';
import {IonicDefaultColor} from '../../types/color/color.types';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(public toastController: ToastController, private translateService: TranslateService) {
    }

    public async presentToast(message: string, color: IonicDefaultColor, duration: number = 5000): Promise<void> {
        const toast = await this.toastController.create(Object.assign(this.getDefaultToastOptions(), {
            color: color,
            duration: duration,
            message: message
        }));
        await toast.present();
    }

    public async presentTranslatedToast(message: string,
                                        color: IonicDefaultColor,
                                        duration: number = 5000,
                                        interpolateParams?: Record<string, any>) {
        const translatedMessage = this.translateService.instant(message, interpolateParams);
        await this.presentToast(translatedMessage, color, duration);
    }

    public async presentToastWithOptions(message: string,
                                         color: IonicDefaultColor,
                                         duration?: number,
                                         action?: (data?: any) => Promise<any>,
                                         thisArg: Record<string, any> = this,
                                         buttonText: string = 'X',
                                         data?: any
    ): Promise<void> {
        const toastButtons: ToastButton[] = [
            {
                side: 'end',
                text: buttonText,
                cssClass: 'toast-button-class',
                handler: async () => {
                    if (action) {
                        action.call(thisArg, data);
                    } else {
                        await toast.dismiss(data, 'action');
                    }
                }
            }
        ];

        const toast = await this.toastController.create(Object.assign(this.getDefaultToastOptions(), {
            color: color,
            duration: duration,
            message: message,
            buttons: toastButtons
        } as ToastOptions));
        return await toast.present();
    }

    public async presentTranslatedToastWithOptions(message: string,
                                                   color: IonicDefaultColor,
                                                   interpolateParams?: Record<string, any>,
                                                   duration: number = 5000,
                                                   action?: (data?: any) => Promise<any>,
                                                   thisArg: Record<string, any> = this,
                                                   buttonText?: string,
                                                   data?: any
    ): Promise<void> {
        const translatedMessage = this.translateService.instant(message, interpolateParams);
        await this.presentToastWithOptions(
            translatedMessage, color, duration, action, thisArg, buttonText, data);
    }

    public async dismissToast() {
        const prevToast: any = await this.toastController.getTop();
        if (prevToast) {
            await prevToast.dismiss();
        }
    }

    private getDefaultToastOptions(): ToastOptions {
        return {
            mode: 'md',
            position: 'top'
        } as ToastOptions;
    }
}
