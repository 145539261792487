import {Injectable} from '@angular/core';
import {CanLoad, Route, UrlSegment} from '@angular/router';
import {NisApp} from '../../types/nis/nis.types';
import {AppService} from './app.service';
import {AppConfigTypes} from '../../types/config/app-config.types';

@Injectable({ providedIn: 'root' })
export class AppGuard implements CanLoad {
    constructor(private appService: AppService) {
    }

    canLoad(route: Route, segments: UrlSegment[]): boolean {
        if (segments.length && !this.appService.app?.length) {
            this.appService.app = segments[0].path as NisApp;
        }
        if (this.appService.isPaApp) {
            route.data = new AppConfigTypes(false, false, false);
        } else if (this.appService.isConnectApp) {
            route.data = new AppConfigTypes(false, true, true);
        }
        this.appService.appConfig = route.data as AppConfigTypes;
        return true;
    }
}
