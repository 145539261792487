import {NgModule} from '@angular/core';
import {Route, RouterModule} from '@angular/router';
import {NxTools} from './nis-lib/types/tool/tool.types';
import {NisApp} from './nis-lib/types/nis/nis.types';
import {adminMatcher, nxMatcher} from './nis-lib/matchers/nis-route.matcher';
import {AppGuard} from './nis-lib/services/app/app-guard.service';
import {AppConfigTypes} from './nis-lib/types/config/app-config.types';

const routes: Route[] = [
    {
        matcher: nxMatcher,
        data: new AppConfigTypes(),
        canLoad: [AppGuard],
        loadChildren: () => import('./nx/nx.module').then(m => m.NxModule)
    },
    {
        matcher: adminMatcher,
        canLoad: [AppGuard],
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: '',
        redirectTo: '/' + NisApp.nx,
        pathMatch: 'full'
    },
    {
        path: 'nx',
        redirectTo: '/' + NisApp.nx,
        pathMatch: 'full'
    },
    {
        path: 'pa',
        redirectTo: '/' + NisApp.pa + '/' + NxTools.planauskunft,
        pathMatch: 'full'
    },
    {
        path: 'connect',
        redirectTo: '/' + NisApp.connect,
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/' + NisApp.nx
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
