import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanLoad, ParamMap, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {NisAuthGuardService} from './nis-auth-guard.service';
import {NisApp, NisAppAuthorization} from '../../types/nis/nis.types';
import {AuthService} from './auth.service';
import {PlatformService} from '../platform/platform.service';
import {AppService} from '../app/app.service';
// eslint-disable-next-line boundaries/no-ignored
import {environment} from '../../../../environments/environment';

@Injectable()
export class NisKeycloakAuthGuard implements NisAuthGuardService, CanLoad {
    constructor(
        private appService: AppService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        private platformService: PlatformService
    ) {
    }

    public async canLoad(): Promise<boolean> {
        if (!this.platformService.isApp) {
            return this.authService.isAuthenticated();
        }
        return true;
    }

    // can be made better with Angular 14+
    // see https://agile4nis.atlassian.net/browse/HZ-5653
    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<UrlTree | boolean> {
        if (!await this.authService.isAuthenticated()) {
            if (this.platformService.isCapacitor) {
                return this.router.createUrlTree([this.appService.app, 'mobile-landing-page']);
            } else if (this.platformService.isElectron) {
                const params = new URLSearchParams(window.location.search);
                return this.router.createUrlTree([this.appService.app, 'mobile-landing-page'],
                    {
                        queryParams: {
                            state: params.get('state'),
                            sessionState: params.get('session_state'),
                            code: params.get('code'),
                            authorization_failure: params.get('authorization_failure')
                        }
                    });
            }
            const baseUrl: string = `${window.location.origin}/${environment.baseUrlSegment}`;
            const fullUrl: string = `${baseUrl}${state.url}`;
            await this.handleUnauthenticatedWeb(route, fullUrl);
        }

        const app: NisApp = route.params['app'] as NisApp;
        const authorizations: NisAppAuthorization[] = route.data.authorizations;
        const authorization: NisAppAuthorization = authorizations.find((auth: NisAppAuthorization) => auth.app === app);
        const roles: string[] = await this.authService.getRoles();
        const accessAllowed: boolean = roles.includes(authorization.role);

        if (!accessAllowed) {
            await this.authService.logout(true);
        }

        return accessAllowed;
    }

    private async handleUnauthenticatedWeb(route: ActivatedRouteSnapshot, fullUrl: string): Promise<void> {
        const isAuthFailure = route.queryParamMap.has('authorization_failure')
            && route.queryParamMap.get('authorization_failure').toLowerCase() === 'true';
        const hasAuthParams = route.queryParamMap.has('state')
            && route.queryParamMap.has('session_state')
            && route.queryParamMap.has('code');
        const newPath = this.getNewPath(route.queryParamMap, fullUrl);
        if (hasAuthParams) {
            await this.authService.handleWebLogin(route.queryParamMap);
            window.location.replace(newPath);
        } else if (isAuthFailure) {
            await this.authService.login(newPath, true);
        } else {
            try {
                await this.authService.login(newPath);
            } catch (err: any) {
                await this.router.navigate(['/']);
            }
        }
    }

    private getNewPath(queryParamMap: ParamMap, url: string): string {
        const newPath: URL = new URL(url);
        const paramsToDelete: Set<string> = new Set(['state', 'session_state', 'code', 'authorization_failure']);
        queryParamMap.keys.forEach((key: string) => {
            if (paramsToDelete.has(key)) {
                newPath.searchParams.delete(key);
            }
        });
        return newPath.toString();
    }
}
