import {Injectable} from '@angular/core';
import {DeviceSecurityType, IdentityVaultConfig, Vault, VaultType} from '@ionic-enterprise/identity-vault';
import {AuthResult} from '@ionic-enterprise/auth';
import {PlatformService} from '../services/platform/platform.service';
import {NisBrowserVault} from './browser-vault.service';
import {StorageService} from './storage.service';
import {ElectronService} from '../services/electron/electron.service';

const config: IdentityVaultConfig = {
    key: 'ch.nis.xplorer',
    type: VaultType.SecureStorage,
    deviceSecurityType: DeviceSecurityType.None,
    lockAfterBackgrounded: 5000,
    shouldClearVaultAfterTooManyFailedAttempts: true,
    customPasscodeInvalidUnlockAttempts: 2,
    unlockVaultOnLoad: false
};

const vaultKey: string = 'auth-result';

@Injectable({
    providedIn: 'root'
})
export class VaultService {
    private vault: Vault | NisBrowserVault;
    private authResult: AuthResult | null = null;

    constructor(private platformService: PlatformService, protected storageService: StorageService,
                private electronService: ElectronService) {
        this.vault = this.platformService.isCapacitor ? new Vault() : new NisBrowserVault(storageService);
    }

    public async initialize(): Promise<void> {
        try {
            await this.vault.initialize(config);
        } catch (err: unknown) {
            await this.vault.clear();
        }
    }

    public clear(): Promise<void> {
        this.authResult = null;
        if (this.platformService.isElectron) {
            return this.electronService.ipcRenderer.invoke('fs-clearAuthResult');
        } else {
            return this.vault.clear();
        }
    }

    public async getSession(): Promise<AuthResult | null> {
        if (!this.authResult) {
            if (this.platformService.isElectron) {
                this.authResult = await this.electronService.ipcRenderer.invoke('fs-getAuthResult');
            } else {
                this.authResult = await this.vault.getValue<AuthResult>(vaultKey);
            }
        }
        return this.authResult;
    }

    public setSession(value: AuthResult): Promise<void> {
        this.authResult = value;
        if (this.platformService.isElectron) {
            this.electronService.ipcRenderer.invoke('fs-safeAuthResult', value);
        } else {
            return this.vault.setValue(vaultKey, value);
        }
    }
}
