import {Component} from '@angular/core';

import {Event, ResolveEnd, Router} from '@angular/router';
import {PlatformService} from './nis-lib/services/platform/platform.service';
import {SplashScreen} from '@capacitor/splash-screen';
import {VaultService} from './nis-lib/storage/vault.service';
import {MobileLoginService} from './nis-lib/services/mobile-login/mobile-login.service';
import {AuthResult} from '@ionic-enterprise/auth';

@Component({
    selector: 'nis-root',
    templateUrl: 'app.component.html'
})
export class AppComponent {
    showApp: boolean = false;

    constructor(private platformService: PlatformService,
                private router: Router,
                private mobileLoginService: MobileLoginService,
                private vaultService: VaultService) {
        this.initializeApp();

        const routerSubscription = this.router.events.subscribe((event: Event) => {
            if (event instanceof ResolveEnd) {
                this.showApp = true;
                routerSubscription.unsubscribe();
            }
        });
    }

    initializeApp() {
        this.platformService.ready().then(() => {
            if (this.platformService.isApp) {
                SplashScreen.hide();
                this.vaultService.getSession().then((authResult: AuthResult) => {
                    if (authResult) {
                        this.vaultService.clear();
                        this.mobileLoginService.login(authResult);
                    }
                });
            }
        });
    }
}
