import {Injectable} from '@angular/core';
import {take} from 'rxjs/operators';
import {ApiVersion} from '../../types/api/api.types';
import {AuthService} from '../auth/auth.service';
import {ApiService} from '../api/api.service';
import {LiveUpdateService} from '../update/live-update.service';
import {TranslateService} from '@ngx-translate/core';
import {CapacitorException} from '@capacitor/core';
import {PlatformService} from '../platform/platform.service';
import {WindowsCompatibilityService} from '../update/windows-compatibility.service';
import {AuthResult} from '@ionic-enterprise/auth';
import {StorageService} from '../../storage/storage.service';
// eslint-disable-next-line boundaries/no-ignored
import {environment} from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class MobileLoginService {
    public loginMessage: string = '';
    public isLoading: boolean = false;

    constructor(private authService: AuthService,
                private apiService: ApiService,
                private liveUpdateService: LiveUpdateService,
                private platformService: PlatformService,
                private translateService: TranslateService,
                private windowsCompatibilityService: WindowsCompatibilityService,
                private storageService: StorageService) {
    }

    async login(authResult: AuthResult = null): Promise<void> {
        this.isLoading = true;
        this.loginMessage = '';

        const apiVersion: ApiVersion = await this.apiService.getApiVersion(this.authService.getServerAddress());
        if (!apiVersion) {
            this.loginMessage = this.translateService.instant('nis.login_page.server_not_reachable');
            this.isLoading = false;
            return;
        }
        if (this.platformService.isCapacitor) {
            this.liveUpdateService.updateCompleted.pipe(take(1)).subscribe(async (updatedOrIgnored: boolean) => {
                this.loginMessage = '';
                if (updatedOrIgnored) {
                    await this.authenticate(authResult);
                }
                this.isLoading = false;
            });

            const doLiveUpdate: boolean = environment.production || ((await this.storageService.get('doLiveUpdate')) ?? true);
            if (doLiveUpdate) {
                const channelName: string = `${apiVersion.major}.${apiVersion.minor}`;
                await this.liveUpdateService.doLiveUpdate(channelName);
            } else {
                this.liveUpdateService.updateCompleted.next(true);
            }
        } else {
            if (await this.windowsCompatibilityService.checkCompatibility(apiVersion)) {
                await this.authenticate(authResult);
            }
            this.isLoading = false;
        }
    }

    private async authenticate(authResult: AuthResult) {
        try {
            if (authResult) {
                await this.authService.resumeMobileSession(authResult);
            } else {
                await this.authService.login();
            }
        } catch (err: any) {
            this.handelAuthenticationErrors(err);
        }
    }

    private handelAuthenticationErrors(err: Error) {
        if (err instanceof CapacitorException && err.message) {
            if (err.message.startsWith('manifest error')) {
                this.loginMessage = this.translateService.instant('nis.login_page.server_not_reachable');
            } else if (err.message === 'user canceled auth') {
                this.loginMessage = this.translateService.instant('nis.login_page.user_canceled_auth');
            } else {
                this.loginMessage = this.translateService.instant('nis.login_page.unknown_error');
            }
        } else {
            this.loginMessage = this.translateService.instant('nis.login_page.unknown_error');
        }
    }
}
