import {Injectable} from '@angular/core';
import {MessageService} from '../message/message.service';
import {AlertMessageButton} from '../../types/general/popup.types';
import {TranslateService} from '@ngx-translate/core';
import {ElectronService} from '../electron/electron.service';
import {ApiVersion} from '../../types/api/api.types';

@Injectable({ providedIn: 'root' })
export class WindowsCompatibilityService {
    private readonly windowsAppLink: string = 'https://nis.ch/#support';

    constructor(private electronService: ElectronService,
                private messageService: MessageService,
                private translateService: TranslateService) {
    }

    public async checkCompatibility(apiVersion: ApiVersion): Promise<boolean> {
        if (await this.isAppVersionCompatibleWithApiVersion(apiVersion)) {
            return true;
        } else {
            const forwardToWebsiteButton = new AlertMessageButton(this.translateService.instant('nis.update'),
                () => {
                    this.electronService.ipcRenderer.invoke('shell-openExternal', this.windowsAppLink);
                });
            this.messageService.displayMessage(
                this.translateService.instant('nis.login.compatibility_new_server_error',
                    { version: `${apiVersion.major}.${apiVersion.minor}` }),
                'nis.login.compatibility_title', [forwardToWebsiteButton]);
            return false;
        }
    }

    private async isAppVersionCompatibleWithApiVersion(apiVersion: ApiVersion): Promise<boolean> {
        const appVersion: string = await this.electronService.getAppVersion();
        const appMajorMinorVersion: string = appVersion.split('.').slice(0, 2).join('.');
        const apiMajorMinorVersion: string = `${apiVersion.major}.${apiVersion.minor}`;
        return appMajorMinorVersion === apiMajorMinorVersion;
    }
}
