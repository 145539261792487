import {Injectable} from '@angular/core';
import {Deferred} from '../../helpers/deferred';
import {TranslateService} from '@ngx-translate/core';
import {NisHttpWrapperService} from '../http/nis-http-wrapper.service';
import {ReplaySubject} from 'rxjs';
import {LanguageTranslation} from '../../types/general/translation.types';
import {StorageService} from '../../storage/storage.service';

@Injectable({
    providedIn: 'root'
})
export class NisTranslateService {
    public translationsLoaded: ReplaySubject<void> = new ReplaySubject<void>();

    constructor(private translateService: TranslateService,
                private storage: StorageService,
                private _http: NisHttpWrapperService) {
    }

    async loadTranslations(): Promise<LanguageTranslation> {
        const deferred: Deferred<LanguageTranslation> = new Deferred<LanguageTranslation>();
        const currentLang: string = this.translateService.currentLang;
        this._http.get<LanguageTranslation>('api/poc/language-js')
            .subscribe(
                (response: LanguageTranslation) => {
                    this.translateService.setTranslation(currentLang, response.values, true);
                    this.storage.set('languageTranslations', response);
                    this.translationsLoaded.next();
                    deferred.resolve(response);
                },
                async (err: unknown) => {
                    const cachedLanguageTranslation: LanguageTranslation = await this.storage.get(
                        'languageTranslations') as LanguageTranslation;
                    this.translateService.setTranslation(currentLang, cachedLanguageTranslation.values, true);
                    this.translationsLoaded.next();
                    deferred.resolve(cachedLanguageTranslation);
                }
            );
        return deferred.promise;
    }

    public init(): void {
        this.translateService.setDefaultLang('de');
        const langToSet: string = this.translateService.getBrowserLang() || this.translateService.currentLang;
        this.translateService.use(langToSet);
    }
}
