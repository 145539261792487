import {AlertButton} from '@ionic/core';

// Class is actually not necessary, because ionic provides AlertButton interfaces which can be used directly.
// But removing AlertMessageButton would cause too many changes because it is used so many times.
// As a workaround, AlertMessageButton implements AlertButton to enforce correct typing.
export class AlertMessageButton implements AlertButton {
    constructor(public text: string, public handler?: (value: any) => boolean | void | {
        [key: string]: any;
    }) {
    }
}
