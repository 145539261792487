import {Injectable, NgZone} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {DeeplinkEvent} from '../../types/deeplink/deeplink.types';
import {Router} from '@angular/router';
import {PlatformService} from '../platform/platform.service';
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DeeplinkService {
    public deeplinkCalled: ReplaySubject<DeeplinkEvent> = new ReplaySubject<DeeplinkEvent>(1);
    public storageEntry: string = 'permalinkUrl';

    constructor(private ngZone: NgZone,
                private router: Router,
                private platformService: PlatformService) {
        if (this.platformService.isCapacitor) {
            this.initializeDeeplinks();
        }
    }

    private initializeDeeplinks() {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            this.ngZone.run(() => {
                const routes = event.url.split(`/${environment.baseUrlSegment}`);
                if (routes.length > 1) {
                    const host = routes[0].split('://');

                    this.deeplinkCalled.next({
                        serverUrl: routes[0],
                        host: host[1],
                        requestUrl: routes[1],
                        sourceUrl: this.router.url
                    });
                }
            });
        });
    }
}
