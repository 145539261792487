export class Deferred<T> {
    public promise: Promise<T>;
    public resolve: (value?: T | PromiseLike<T>) => void;
    public reject: (reason?: any) => void;

    constructor() {
        this.promise = new Promise(
            (resolve: (value?: T | PromiseLike<T>) => void,
             reject: (reason?: any) => void) => {
                this.reject = reject;
                this.resolve = resolve;
            });
    }
}
